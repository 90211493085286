<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col cols="4" offset="6">
                <text-field v-model="filters.userName" label="username"/>
            </v-col>
            <v-col cols="2">
                <v-btn class="btn-accent" block text @click="handleFilter">filter</v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :items-per-page="5"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:item.user="{ item }">
                {{ item.user.slug }}
            </template>
            <template v-slot:item.status="{ item }">
                {{ statuses[item.status] }}
            </template>
            <template v-slot:item.payment_system="{ item }">
                {{ paymentSystems[item.payment_system] }}
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import WithdrawalBankTransfer from '../../../../models/admin/WithdrawalBankTransfer';
import AdminDashboardLayout from "../../../../layouts/AdminDashboardLayout";
import moment from "moment";
import {paymentSystems, statuses} from "@/views/admin/withdrawals/withdrawal";
import TextField from "@/components/app/form/TextField.vue";

export default {
    name: "withdrawals-index",
    components: {TextField, AdminDashboardLayout},
    data: function () {
        return {
            filters: {
                userName: '',
            },
            loading: false,
            items: [],
            headers: [
                {text: 'User', value: 'user'},
                {text: 'Amount In', value: 'amount_in'},
                {text: 'Commission', value: 'commission'},
                {text: 'Bank Fee', value: 'additional_fee'},
                {text: 'Amount out', value: 'amount_out'},
                {text: 'Status', value: 'status'},
                {text: 'Payment System', value: 'payment_system'},
                {text: 'Created time', value: 'created_at'},
            ],
        }
    },
    computed: {
        statuses() {
            return statuses
        },
        paymentSystems() {
            return paymentSystems
        }
    },
    methods: {
        handleClickRow(items) {
            this.$router.push({name: 'admin.withdrawals.bank-transfer.show', params: {id: items.id}})
        },
        handleFilter() {
            this.fetchWithdrawals()
        },
        async fetchWithdrawals() {
            this.loading = true
            let qb = WithdrawalBankTransfer
            if (this.filters.userName) {
                qb = qb.where('user-name', this.filters.userName)
            }
            qb = qb.whereIn('status', [1, 0])
            this.items = await qb.get()
            this.items.map(v => v.created_at = moment(v.created_at).format('lll'))
            this.loading = false
        }
    },
    created() {
        this.fetchWithdrawals()
    },
}
</script>
